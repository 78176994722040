import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Printad_Web from './Components/Printad_Web';
import Scriptnew from './Components/Scriptnew';

function App() {
  return (
   <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Printad_Web />} />
          <Route path="/learn-more" element={<Scriptnew />} />
        </Routes>
      </BrowserRouter>
   </>
  );
}

export default App;
